.service-list {
  background: white;
  padding: $grid-gutter-width*3 0;
  .row > div:nth-child(odd) {
    clear: both;
  }
  &__item {
    padding: 0;
    margin-bottom: $grid-gutter-width;
    &__anchor {
      display: block;
      position: relative;
      top: -70px;
      visibility: hidden;
    }
    &__content {
      padding: $grid-gutter-width/2;
      &.selected {
        border-radius: (80px/2);
        box-shadow: 0px 0px 10px #ccc;
        color: inherit;
      }
    }
    .image-col {
      padding: 0 $grid-gutter-width/2;
      img {
        margin: 0 auto;
      }
    }
    .content-col {
      padding: 0 $grid-gutter-width/2;
      p {
        margin: 0;
      }
    }
    .field-image {
      border-radius: 50%;
    }
    .field-name {
      margin: (80px - $font-size-h2)/2 0;
      line-height: $font-size-h2;
      font-size: $font-size-h2;
      font-weight: bold;
      text-align: center;
    }
    .field-description {
      font-size: $font-size-large;
    }
    .field-link {
      margin-top: $line-height-computed;
      font-size: $font-size-large;
    }
    @media (min-width: $screen-md-min) {
      .image-col,
      .content-col {
        float: left;
      }
      .image-col {
        width: (80px + $grid-gutter-width);
        padding: 0 $grid-gutter-width/2;
        img {
          margin: 0;
        }
      }
      .content-col {
        width: 345px;
        padding: 0 $grid-gutter-width/2;
      }
      .field-name {
        text-align: left;
      }
    }
    @media (min-width: $screen-lg-min) {
      .content-col {
        width: 445px;
      }
    }
  }
}

.talk-to-us {
  position: relative;
  padding: $grid-gutter-width*2 0;
  background: #787c8a no-repeat center;
  background-size: cover;
  text-align: center;
  h2 {
    font-size: 50px;
    color: white;
    margin-bottom: $grid-gutter-width;
  }
  .btn {
    padding: $grid-gutter-width $grid-gutter-width*2;
    background: white;
    border: none;
    border-radius: ($font-size-h2 + $grid-gutter-width*2)/2;
    line-height: $font-size-h2;
    font-size: $font-size-h2;
    font-weight: bold;
    &:hover,
    &:focus {
      background: $gray-lighter;
    }
  }
}

.service-list-other {
  background: white;
  padding-top: $grid-gutter-width*2;
  h2 {
    margin-bottom: $grid-gutter-width;
    font-size: 50px;
    text-transform: uppercase;
  }
  @media (min-width: $screen-lg-min) {
    h2 {
      text-align: center;
    }
    &__property {
      background: url(../imgs/other_services_circle_1.png) center top no-repeat;
    }
    &__guest {
      background: url(../imgs/other_services_circle_2.png) center top no-repeat;
    }
    .home-services {
      &__part2 {
        height: 390px;
        h3 {
          top: 110px;
        }
        .list-left,
        .list-right {
            padding-top: 15px;
        }
        .list-left {
          .line-1 {
            padding-right: 185px;
          }
          .line-2 {
            padding-right: 215px;
          }
          .line-3 {
            padding-right: 215px;
          }
          .line-4 {
            padding-right: 185px;
          }
        }
        .list-right {
          .line-1 {
            padding-left: 185px;
          }
          .line-2 {
            padding-left: 215px;
          }
          .line-3 {
            padding-left: 215px;
          }
          .line-4 {
            padding-left: 185px;
          }
        }
      }
    }
  }
}
