.home-services {
  background: white;
  &__header {
    padding: $grid-gutter-width*3 0;
    background: #ebf1f5;
    .widget h2 {
      margin-bottom: $grid-gutter-width/2;
      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
  h3 {
    margin-bottom: $grid-gutter-width;
  }
  &__part1,
  &__part2 {
    padding-top: $grid-gutter-width*2;
    padding-bottom: $grid-gutter-width*2;
  }
  &__part2 {
    padding-top: 0;
  }
  .list-left,
  .list-right {
    a {
      display: block;
      color: $text-color;
      &:hover {
        box-shadow: 0px 0px 10px #ccc;
      }
    }
    .field-image {
      float: left;
      border-radius: 50%;
    }
  }
  &__part1 {
    .list-left,
    .list-right {
      a {
        height: (80px + $grid-gutter-width);
        padding: $grid-gutter-width/2;
        border-radius: (80px + $grid-gutter-width)/2;
      }
      .field-name {
        padding-top: (80px / 2 - $line-height-computed / 2);
        padding-left: (80px + $grid-gutter-width/2);
        line-height: $line-height-computed;
        font-size: $font-size-large;
      }
    }
  }
  &__part2 {
    .list-left,
    .list-right {
      a {
        height: (70px + $padding-large-vertical*2);
        padding: $padding-large-vertical;
        border-radius: (70px + $padding-large-vertical*2)/2;
      }
      .field-name {
        padding-top: (70px / 2 - $line-height-computed / 2);
        padding-left: (70px + $grid-gutter-width/2);
        line-height: $line-height-computed;
        font-size: $font-size-base;
      }
    }
  }
  @media (min-width: $screen-lg-min) {
    position: relative;
    z-index: 1;
    &__header {
      padding: $grid-gutter-width*2;
    }
    &__bg {
      height: 1146px - 46px;
      background: white;
      overflow: visible;
    }
    &__bg-img {
      height: 1146px;
      background: url(../imgs/home_services_bg.png) center -2px no-repeat;
    }
    &__part1,
    &__part2 {
      padding-top: 0;
      padding-bottom: 0;
    }
    &__part1 {
      position: relative;
      height: (1146px/2);
      h3 {
        position: absolute;
        z-index: 1;
        top: 180px;
        left: 50%;
        width: 400px;
        margin-left: -200px;
        text-align: center;
        font-size: 45px;
        color: white;
        strong {
          display: block;
        }
      }
      .list-left,
      .list-right {
        padding-top: 130px;
      }
      .list-left {
        text-align: right;
        .line-1 {
          padding-right: 320px;
        }
        .line-2 {
          padding-right: 275px;
        }
        .line-3 {
          padding-right: 230px;
        }
        .field-image {
          float: right;
        }
        .field-name {
          padding-left: 0;
          padding-right: (80px + $grid-gutter-width/2);
        }
      }
      .list-right {
        text-align: left;
        .line-1 {
          padding-left: 320px;
        }
        .line-2 {
          padding-left: 275px;
        }
        .line-3 {
          padding-left: 230px;
        }
      }
    }
    &__part2 {
      position: relative;
      height: (1146px/2);
      h3 {
        position: absolute;
        z-index: 1;
        top: 260px;
        left: 50%;
        width: 350px;
        margin-left: -175px;
        text-align: center;
        font-size: 40px;
        color: white;
        strong {
          display: block;
        }
      }
      .list-left,
      .list-right {
        padding-top: 125px;
      }
      .list-left {
        text-align: right;
        .line-1 {
          padding-right: 140px;
        }
        .line-2 {
          padding-right: 200px;
        }
        .line-3 {
          padding-right: 230px;
        }
        .line-4 {
          padding-right: 200px;
        }
        .field-image {
          float: right;
        }
        .field-name {
          padding-left: 0;
          padding-right: (80px + $grid-gutter-width/2);
        }
      }
      .list-right {
        text-align: left;
        .line-1 {
          padding-left: 140px;
        }
        .line-2 {
          padding-left: 200px;
        }
        .line-3 {
          padding-left: 230px;
        }
        .line-4 {
          padding-left: 200px;
        }
      }
    }
  }
}
