@import "variables";
@import "../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

@import "../../../../static/base/css/common";

body {
  // overflow-y: scroll;
  font-weight: 400;
}

.modal-header {
  background: $brand-primary;
  border-bottom: none;
}
.modal-header,
.modal-title {
  color: white;
}

@import "home";
@import "about";
@import "service_list";
@import "contacts";
